<template>
  <div class="base-background">
    <div class="d-flex justify-content-center">
      <a-row class="outer-card p-3">
        <a-col class="header-card">
          <h3 class="pb-5">Document Verification Status</h3>
          <a-descriptions :column="{ xxl: 3, xl: 3, lg: 3, md: 1, sm: 1, xs: 1 }" class="bg-white" bordered>
            <a-descriptions-item label="Homeroom Teacher Name">
              {{ verificationData.homeroomName }}
            </a-descriptions-item>
            <a-descriptions-item v-if="verificationData.homeroomDate" label="Verification Status">
              <a-icon type="check-circle" theme="twoTone" two-tone-color="green" />
            </a-descriptions-item>
            <a-descriptions-item v-else label="Verification Status">
              <a-icon type="close-circle" theme="twoTone" two-tone-color="red" />
            </a-descriptions-item>
            <a-descriptions-item label="Verification Date">
              {{ verificationData.homeroomDate ? moment(verificationData.homeroomDate).format('DD MMMM YYYY HH:mm:ss') :
            '-' }}
            </a-descriptions-item>
            <a-descriptions-item label="Principal Name">
              {{ verificationData.principalName }}
            </a-descriptions-item>
            <a-descriptions-item v-if="verificationData.principalDate" label="Verification Status">
              <a-icon type="check-circle" theme="twoTone" two-tone-color="green" />
            </a-descriptions-item>
            <a-descriptions-item v-else label="Verification Status">
              <a-icon type="close-circle" theme="twoTone" two-tone-color="red" />
            </a-descriptions-item>
            <a-descriptions-item label="Verification Date">
              {{ verificationData.principalDate ? moment(verificationData.principalDate).format('DD MMMM YYYY HH:mm:ss')
            :
            '-' }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
        <a-col :span="24" class="col-report">
          <div class="bg-white shadow rounded p-5 mt-3 content-report">
            <div class="detail" style="min">
              <template v-if="versionReport === null">
                <report-mid-view v-if="generalReport.tipe_report === 'mid'" :configSchool="configSchool"
                  :generalReport="generalReport" />
                <report-final-view v-if="generalReport.tipe_report === 'final'" :configSchool="configSchool"
                  :generalReport="generalReport" />
              </template>
              <template v-if="versionReport === 1">
                <report-mid-view-v2 v-if="generalReport.tipe_report === 'mid'" :configSchool="configSchool"
                  :dataReport="generalReport" :kumer="kumer" />
                <report-final-view-v2 page v-if="generalReport.tipe_report === 'final'" :configSchool="configSchool"
                  :dataReport="generalReport" :kumer="kumer" />
              </template>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import config from '@/config'

export default {
  components: {
    ReportMidView: () => import('@/components/app/Report/Mid'),
    ReportMidViewV2: () => import('@/components/app/Report/Mid/v2'),
    ReportFinalView: () => import('@/components/app/Report/Final'),
    ReportFinalViewV2: () => import('@/components/app/Report/Final/v2'),
  },
  data() {
    return {
      configSchool: '',
      generalReport: {},
      verificationData: {
        homeroomName: null,
        homeroomDate: null,
        principalName: null,
        principalDate: null,
      },
      versionReport: null,
      kumer: false,
    }
  },
  methods: {
    moment,
    async fetchDataReport() {
      const generalReport = await this.$store.dispatch('report/FETCH_METADATA_PUBLIC_REPORT_PREVIEW', { uuid: this.$route.params.uuid })
      // console.log(':>> ',
      // console.log(generalReport)
      this.generalReport = generalReport
      this.verificationData.homeroomName = generalReport.nama_walikelas || ''
      this.verificationData.homeroomDate = generalReport.date_verify_walikelas || null
      this.verificationData.principalName = generalReport.nama_kepala_sekolah || ''
      this.verificationData.principalDate = generalReport.date_verify_kepala_sekolah || null
      this.versionReport = generalReport.version
      this.kumer = generalReport.kumer
      // console.log(this.verificationData)
    },
  },
  mounted() {
    this.configSchool = config.school
    this.fetchDataReport()
  },
}
</script>

<style lang="scss">
/* .card-full {
  padding: 20px 20px;
  padding-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0419301a;
}
.detail {
  padding: 20px;
} */
.base-background {
  margin-top: 0 !important;
  background-color: whitesmoke;
  /* padding-bottom: 100px; */
}

.outer-card {
  width: 100%;

  @media (min-width: 768px) {
    width: 75%;
  }

  .col-report {
    @media (max-width: 768px) {
      overflow: scroll;
      width: 100%;
    }

    .content-report {
      @media (max-width: 768px) {
        overflow: scroll;
        min-width: 800px;
      }
    }
  }
}

/* .header-card {
  background-color: whitesmoke;
  padding-top: 50px;
  padding-bottom: 50px;
} */
</style>
